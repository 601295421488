<template>
  <div class="product_wrap">
    <div style="background:#f5f5f5;width:100%;">
      <section>
        <div class="left_sec">
          <div class="main_item">
            <div class="item">
              <img :src="product.url | imagePath">
              <div class="name" v-text="product.name"></div>
              <div class="price" v-show="0">商品说明:</div>
              <div class="introduction" v-show="0"></div>
            </div>
          </div>
        </div>
        <div class="right_sec flex-y">
          <div class="top_fliter">
            <div class="fliter_conditions" @click="changeTab(1)">
              <span :class="[tabIndex === 1 ? 'up' : 'down']">优质商家</span>
            </div>
            <div class="fliter_conditions" @click="changeTab(2)">
              <span :class="[tabIndex === 2 ? 'up' : 'down']">产品详情</span>
            </div>
          </div>
          <div class="w100 flex-1 flex-y" v-show="tabIndex === 1">
            <div class="address">
              <div class="address_sesect flex align-center">
                <label style="margin-right:10px;min-width:30px;">地区</label>
                <CitySelect @change="cityChange"></CitySelect>
                <div class="flex align-center" @click="sortDistance">
                  <label class="l_distance">距离</label>
                  <img
                    src="../../assets/image/shangshen.png"
                    :class="[param.distance === 'asc' ? '' : 'r180']"
                  >
                </div>
                <div class="flex align-center" @click="sortPrice">
                  <label class="l_price">价格</label>
                  <img
                    src="../../assets/image/shangshen.png"
                    :class="[param.price === 'asc' ? '' : 'r180']"
                  >
                </div>
              </div>
            </div>
            <div class="productList">
              <Item
                v-for="(item, index) in list"
                :key="index"
                :product="item"
                :index="index+1"
                :total="compareList.length"
                @onCompare="onCompareHandler($event)"
              ></Item>
            </div>
            <Pagination :total="total" @num="getNum"></Pagination>
          </div>
          <div v-show="tabIndex === 2" class="w100" style="padding-top:20px;">
            <div v-html="product.seoDescription"></div>
          </div>
        </div>
      </section>
      <Footer></Footer>
      <Bar
        :list.sync="compareList"
        @unSelect="unSelect($event)"
        @showDialog="centerDialogVisible = true"
      ></Bar>
      <el-dialog title="请填写比价需求人联系信息" :visible.sync="centerDialogVisible" center>
        <el-form :model="concat" status-icon ref="form" :rules="rules" label-width="140px">
          <el-form-item label="需求联系人" prop="userName">
            <el-input v-model="concat.userName" auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item label="联系人电话" prop="phone">
            <el-input v-model="concat.phone" auto-complete="off" :maxlength="11"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm">提交</el-button>
            <el-button @click="resetForm">重置</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import CitySelect from '@/components/common/citySelect'
import Pagination from '@/components/common/pagination'
import Bar from './compareBar'
import Item from './item'
import mixins from 'mixins'
export default {
  components: { Item, Bar, CitySelect, Pagination },
  mixins: [mixins],
  data() {
    return {
      list: [],
      tabIndex: 1,
      compareList: [],
      product: {},
      pageSize: 10,
      total: 0,
      centerDialogVisible: false,
      param: {
        acode: '',
        distance: 'asc',
        page: 1,
        rows: 10,
        praise: 'asc',
        price: 'asc',
        sales: 'asc',
        // lon: 119.939826,
        // lat: 32.486322
          lat1: localStorage.getItem ('lat')+ "",
          lon1: localStorage.getItem ('lng') + "",
      },
      concat: {
        userName: '',
        phone: ''
      },
      rules: {
        userName: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        phone: [
          { required: true, message: '请输入联系方式', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (!this.$check.phone(value)) {
                callback('联系方式格式不正确')
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ]
      }
    }
  },
  mounted() {
    // const { query } = this.$route
    // const { id } = query
    console.log(this.$route.params.id)
    let id =this.$route.params.id;
    this.param['id'] = id;
    this.queryList()
    this.queryInfo(id)
  },
  methods: {
    queryList() {
      this.$http.post(`/storeProductInfo/findByProductId/${this.param.id}`, this.param).then(res => {
        const { data, totalSize } = res.data.data
        this.total = totalSize
        this.list =
          data &&
          data.map(item => {
            item['select'] = false
            return item
          })
      })
    },
    queryInfo(id) {
      this.$http.get('/product/getByIdOfDetails', { params: { id } }).then(res => {
        const { data } = res.data
        this.product = data
      })
    },
    changeTab(index) {
      this.tabIndex = index
    },
    /**
     * @description: 比价选中与未选
     * @param {type}
     * @return:
     */
    onCompareHandler(e) {
      const { select } = e
      const index = this.compareList.findIndex(item => item.id === e.id)
      if (select) {
        this.compareList.push(e)
      } else {
        this.compareList.splice(index, 1)
      }
    },
    /**
     * @description: 距离排序
     * @param {type}
     * @return:
     */
    sortDistance() {
      this.param.distance = this.param.distance === 'asc' ? 'desc' : 'asc'
      this.queryList()
    },
    /**
     * @description: 价格排序
     * @param {type}
     * @return:
     */
    sortPrice() {
      this.param.price = this.param.price === 'asc' ? 'desc' : 'asc'
      this.queryList()
    },
    /**
     * @description: 选择/未选择比价
     * @param {type}
     * @return:
     */
    unSelect(e) {
      if (e !== undefined) {
        this.list.map(item => {
          if (item.id === e.id) {
            item['select'] = false
          }
          return item
        })
      } else {
        this.list.map(item => {
          item['select'] = false
          return item
        })
      }
      this.compareList = this.compareList.filter(item => item.select === true)
    },
    getNum(val) {
      this.param.page = val
      this.queryList()
    },
    submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          const param = {
            productId: this.$route.query.id,
            storeIdList: this.compareList.map(item => item.id),
            linkMan: this.concat.userName,
            phone: this.concat.phone
          }
          this.$http.post(this.$API.compare, param).then(res => {
            const { code, msg } = res.data
            if (code * 1 === 0) {
              this.compareList = []
              this.centerDialogVisible = false
              this.$message({
                message: msg,
                type: 'success',
                onClose: () => {
                  this.$router.push({ name: 'myPrice' })
                }
              })
            } else {
              this.$message({
                message: msg,
                type: 'error'
              })
            }
          })
        } else {
          return false
        }
      })
    },
    resetForm() {
      this.$refs['form'].resetFields()
    },
    cityChange(e) {
      console.log(e)
      const { proviceItem, cityItem, regionItem } = e
      this.param.acode = proviceItem.adcode || cityItem.adcode || regionItem.adcode
      this.queryList()
    }
  }
}
</script>

<style lang="scss" scoped>
.product_wrap {
  width: 100%;
  section {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 20px;
    display: flex;
    box-sizing: border-box;
    .left_sec {
      width: 320px;
      height: 700px;
      overflow: hidden;
      margin-right: 20px;
      background: #fff;
      padding: 0 10px;
      box-sizing: border-box;
      .title {
        line-height: 40px;
        height: 40px;
        text-align: center;
        color: #fff;
        font-size: 15px;
        background: #0db168;
      }
      .main_item {
        width: 100%;
        .item {
          width: 100%;
          background: #fff;
          margin: 20px auto 0;
          img {
            width: 100%;
            height: 280px;
            min-height: 280px;
          }
          div {
            text-align: left;
            &.name {
              font-size: 16px;
              color: #333;
              font-weight: bold;
              margin-top: 15px;
            }
            &.price {
              font-size: 14px;
              font-weight: bold;
              color: #333;
              margin-top: 25px;
            }
            &.introduction {
              font-size: 16px;
              margin-top: 10px;
              color: #333;
            }
          }
        }
      }
    }
    .right_sec {
      flex: 1;
      background: #f5f5f5;
      .top_fliter {
        height: 40px;
        text-align: left;
        .fliter_conditions {
          width: 100px;
          text-align: center;
          line-height: 40px;
          font-size: 15px;
          display: inline-block;
          span {
            display: inline-block;
            height: 100%;
            width: 100%;
            &.up {
              background-size: 16px;
              color: #f5f5f5;
              background-color: rgb(13, 179, 104);
              cursor: pointer;
            }
            &.down {
              background-size: 16px;
              cursor: pointer;
              background: #fff;
            }
          }
        }
      }
      .productList {
        overflow: hidden;
      }
      .pagination {
        text-align: center;
        clear: both;
        margin: 40px 0;
        .inputNum {
          background: transparent;
          width: 34px;
          height: 34px;
          border: none;
          outline: none;
          text-align: center;
          border: 1px solid #e5e5e5;
        }
        div {
          display: inline-block;
          height: 34px;
          line-height: 34px;
          text-align: center;
          color: #333333;
          font-size: 14px;
          margin: 0 5px;
          padding: 0 14px;
          box-sizing: border-box;
          &.active {
            border: 1px solid #0db168 !important;
          }
          &.border {
            border: 1px solid #e5e5e5;
            &:hover {
              border: 1px solid #0db168 !important;
            }
          }
        }
      }
    }
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}
.left_sec::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.left_sec {
  -ms-scroll-chaining: chained;
  -ms-overflow-style: none;
  -ms-content-zooming: zoom;
  -ms-scroll-rails: none;
  -ms-content-zoom-limit-min: 100%;
  -ms-content-zoom-limit-max: 500%;
  -ms-scroll-snap-points-x: snapList(100%, 200%, 300%, 400%, 500%);
  -ms-overflow-style: none;
  overflow: auto;
}
.left_sec .-o-scrollbar {
  -moz-appearance: none !important;
  background: rgba(0, 255, 0, 0) !important;
}
.r180 {
  transform: rotate(180deg);
  margin: 0;
}
.address {
  margin: 20px 20px 20px 0;
  font-size: 15px;
  .address_sesect {
    .l_distance {
      margin-left: 30px;
      min-width: 30px;
      margin-right: 5px;
      color: rgb(12, 178, 103);
      cursor: pointer;
      + img {
        cursor: pointer;
      }
    }
    .l_price {
      margin-left: 20px;
      min-width: 30px;
      margin-right: 5px;
      color: rgb(12, 178, 103);
      cursor: pointer;
      + img {
        cursor: pointer;
      }
    }
  }
}
</style>
