<template>
  <div class="flex align-center">
    <el-select
      v-model="provice"
      filterable
      placeholder="请选择"
      size="small"
      class="select"
      value-key="id"
      @change="changeHanlder($event, 1)"
      clearable
    >
      <el-option v-for="item in provinceList" :key="item.id" :label="item.name" :value="item.id"></el-option>
    </el-select>
    <el-select
      v-model="city"
      filterable
      placeholder="请选择"
      size="small"
      value-key="id"
      class="select"
      @change="changeHanlder($event, 2)"
      clearable
    >
      <el-option v-for="item in cityList" :key="item.id" :label="item.name" :value="item.id"></el-option>
    </el-select>
    <el-select
      v-model="area"
      filterable
      placeholder="请选择"
      size="small"
      value-key="id"
      class="select"
      @change="changeHanlder($event, 3)"
      clearable
    >
      <el-option v-for="item in areaList" :key="item.id" :label="item.name" :value="item.id"></el-option>
    </el-select>
  </div>
</template>
<script>
export default {
  props: ['pid', 'cid', 'rid'],
  data() {
    return {
      provinceList: [],
      cityList: [],
      areaList: [],
      provice: this.pid,
      city: this.cid,
      area: this.rid
    }
  },
  mounted() {
    this.queryProvince()
    if (this.pid) {
      this.queryData(this.pid, 1)
    }
    if (this.cid) {
      this.queryData(this.cid, 2)
    }
  },
  methods: {
    queryProvince() {
      this.$http.get('/area/provence').then(res => {
        const { data } = res.data
        this.provinceList = data
      })
    },
    changeHanlder(e, i) {
      let province = [], city = [], area = []
      if (i < 3) {
        if (i === 1) {
          province = this.provinceList.filter(item => item.id === e)
          this.city = ''
          this.area = ''
          this.cityList = []
          this.areaList = []
        } else {
          city = this.cityList.filter(item => item.id === e)
          this.area = ''
          this.areaList = []
        }
        if (e) this.queryData(e, i)
      } else {
        area = this.areaList.filter(item => item.id === e)
      }
      this.$emit('change', {
        province: this.provice,
        city: this.city,
        region: this.area,
        proviceItem: province.length > 0 ? province[0]: {},
        cityItem: city.length > 0 ? city[0] : {},
        regionItem: area.length > 0 ? area[0]: {}
        })
    },
    queryData(id, i) {
      this.$http.get('/area/next', { params: { areaId: id } }).then(res => {
        const { data } = res.data
        i === 1 ? (this.cityList = data) : (this.areaList = data)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.select {
  font-size: 15px;
  flex: 1 !important;
  margin: 0 10px 0 0;
}
/deep/ .el-input__inner{
  width: 100% !important;
}
</style>
