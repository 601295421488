<template>
  <div id="pagination">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage1"
      :page-size="10"
      layout="total, prev, pager, next, jumper"
      :total="total"
      background
    ></el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentPage1: 1,
      pageSize: 10,
      num: 1
    }
  },
  mounted() {},
  props: {
    total: {
      type: Number,
      required: true
    }
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`, 'size')
    },
    handleCurrentChange(val) {
      this.$emit('num', val)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../assets/scss/change.scss';
#pagination {
  margin: 20px auto;
  display: flex;
  align-items: center;
}
/* 修改element分页公共样式 */
/deep/ .el-select .el-input.is-focus .el-input__inner {
  border-color: #0db168 !important;
}

.el-select .el-input__inner:focus {
  border-color: #0db168 !important;
}

.el-select-dropdown__item.selected {
  color: #0db168 !important;
}

.el-select:hover .el-input__inner:hover {
  border-color: #0db168 !important;
}

.el-pagination .btn-next .el-icon,
.el-pagination .btn-prev .el-icon:hover {
  color: #0db168 !important;
}
.el-step__icon {
  background: #0db168 !important;
  color: #fff;
  border: 0px solid !important;
}
.el-step__title.is-process {
  color: #333 !important;
  font-size: 16px !important;
  font-weight: normal !important;
}
/deep/ .el-pagination.is-background .el-pager li{
    margin: 0 5px;
    background-color: #FFFFFF;
    color: #606266;
    min-width: 30px;
    border-radius: 2px;
}
</style>


