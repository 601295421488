<template>
  <div class="product flex align-center">
    <h3 v-text="index"></h3>
    <img :src="product.logoUrl | imagePath" @click="enterStore">
    <div class="flex-1 h100 flex-y flex-center align-start">
      <div class="productName flex align-center" @click="enterStore">
        <span style="font-size: 18px;font-weight: bold;cursor:pointer;" v-text="product.storeName"></span>
        <img src="../../assets/image/renzheng.png" alt style="margin-left: 20px">
      </div>
      <div class="left_add flex align-center">
        <img src="../../assets/image/dingwei.png" alt style="margin-right: 10px">
        <span v-text="product.address"></span>
        <label style="margin-left: 52px">距您{{product.distance}}公里</label>
      </div>
    </div>
    <div class="price">￥{{product.price | price(2)}}</div>
    <div class="right_add">
      <el-tooltip effect="light" placement="left" popper-class="providers-tooltip primary">
        <div slot="content" class="flex-y">
          <span style="color:#0db168;margin-bottom:5px;">查看详情</span>
          <span class="f12">进入产品详情页选择产品属性或客服</span>
          <span class="f12">在线议价,可即时提交订单</span>
        </div>
        <el-button type="primary" size="small" class="btn" @click="detail">查看详情</el-button>
      </el-tooltip>
      <!-- <el-tooltip effect="light" placement="left" popper-class="providers-tooltip danger">
        <div slot="content" class="flex-y">
          <span style="color:#FF9600;margin-bottom:5px;">邀约比价</span>
          <span class="f12">您可以选择3-5家商家同时发出报价邀请</span>
          <span class="f12">商家收到您的邀约后将会参与报价</span>
          <span class="f12">商家报价完毕后，您可以选择最适合的商家为您服务</span>
        </div>
        <el-button
          type="warning"
          size="small"
          class="btn"
          :class="[product.select ? 'on' : 'un']"
          @click="compare"
        >邀约比价</el-button>
      </el-tooltip> -->
    </div>
  </div>
</template>
<script>
import {mapMutations} from 'vuex'
export default {
  props: {
    product: {
      type: Object,
      required: true,
      default: () => {}
    },
    index: {
      type: Number
    },
    total: {
      type: Number
    }
  },
  data() {
    return {}
  },
  methods: {
     ...mapMutations({ saveStore: 'STORE_INFO' }),
    compare() {
      let { select } = this.product
      if (this.total === 5 && !select) return
      this.product['select'] = !select
      this.$emit('onCompare', this.product)
    },
    detail() {

      const { id } = this.product
      const pid  = this.$route.params.id

      this.$router.push({ path: '/productDetail', query: { sid: id, pid: pid } })

    },
    enterStore () {
      this.saveStore(this.product)
      this.$router.push({ name: 'shopPage', params: { id: this.product.id} })
    }
  }
}
</script>
<style lang="scss" scoped>
.product {
  width: 100%;
  height: 104px;
  background: #fff;
  margin-bottom: 10px;
  h3 {
    font-size: 18px;
    text-align: center;
    width: 72px;
    color: #333;
  }
  > img {
    cursor: pointer;
    width: 72px;
    min-width: 72px;
    height: 72px;
    vertical-align: middle;
    margin-right: 20px;
    border: none;
  }

  .productPrice {
    color: #ff0036;
    font-size: 18px;
    margin-top: 10px;
  }
  .productName {
    margin-bottom: 20px;
  }
  .price {
    min-width: 130px;
    margin: 0 20px;
    font-size: 24px;
    color: #ff0036;
  }
  .left_add {
    color: #666;
    span {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        width: 1px;
        height: 80%;
        border-left:1px solid #cccccc;
        right: -30px;
        top: 2px;
      }
    }
  }
  .right_add {
    float: right;
    margin-top: 15px;
    margin-right: 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    .btn {
      width: 106px;
      text-align: center;
      border-radius: 2px;
      margin: 0 auto;
      background: #0db168;
      border: none;
      &:nth-child(2) {
        margin-top: 10px;
        border: 1px solid #ff9600;
        background: #fff;
        color: #ff9600;
        &.on {
          background: #ff9600;
          color: #fff;
        }
      }
    }
  }
}
</style>
