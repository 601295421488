<template>
  <div class="compare_bar" v-show="show">
    <div class="bar flex-y">
      <div class="title" v-text="text" v-show="list.length === 5"></div>
      <div class="subtitle flex align-center flex-between">
        <div class="flex align-center">
          <span v-text="title"></span>
          <span>比价栏(最多可同时5家比价)</span>
        </div>
        <div class="btn" @click="show = false">隐藏</div>
      </div>
      <div class="flex content align-center">
        <div class="item flex align-center" v-for="(item, index) in itemList" :key="index">
          <img :src="item.logoUrl | imagePath">
          <div class="flex-y align-start">
            <span v-text="item.storeName"></span>
            <span>￥{{item.price | price}}</span>
            <span @click="remove(item, index)">删除</span>
          </div>
        </div>
        <div class="item flex align-center empty" v-for="item in emptyList" :key="item">
          <div class="number" v-text="item"></div>
          <span>您还可以继续添加</span>
        </div>
        <div class="add flex-y align-center flex-center">
          <el-button :type="list.length > 1 ? 'primary' : 'info'" size="small" @click="compare">立即比价</el-button>
          <span @click="clear">清空</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array
    },
    title: {
      type: String
    }
  },
  data() {
    return {
      show: false,
      text: '比价栏已满，您可以删除不需要的比价商家再继续添加哦！',
      emptyList: [1, 2, 3, 4, 5],
      itemList: [],
      centerDialogVisible: false
    };
  },
  methods: {
    clear() {
      this.itemList = [];
      this.emptyList = [1, 2, 3, 4, 5];
      this.$emit('unSelect');
    },
    remove(item, index) {
      this.$emit('unSelect', item);
    },
    compare() {
      if (this.itemList.length < 2) {
        this.$message({
          message: '请至少选择两家服务商进行比价',
          type: 'error'
        });
        return;
      }
      this.$emit('showDialog');
    }
  },
  watch: {
    list(n) {
      this.itemList = n;
      const { length } = n;
      if (length > 0) this.show = true;
      this.emptyList = [];
      for (let i = 1; i <= 5 - length; i++) {
        this.emptyList.push(length + i);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.compare_bar {
  height: 150px;
  position: fixed;
  width: 100%;
  text-align: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  z-index: 1000;
}
.bar {
  width: 100%;
  height: 100%;
  max-width: 1200px;
  background: #fff;
  .title {
    width: 100%;
    max-width: 1200px;
    height: 30px;
    line-height: 30px;
    background-color: #c0e6d5;
    margin: 0 auto;
    font-size: 12px;
    color: #0db168;
    text-align: center;
  }
  .subtitle {
    width: 100%;
    max-width: 1200px;
    height: 30px;
    background-color: #0db168;
    margin: 0 auto;
    font-size: 12px;
    color: #fff;
    font-weight: 500;
    padding: 0 20px;
    box-sizing: border-box;
    span {
      &:nth-of-type(2) {
        margin-left: 20px;
      }
    }
    .btn {
      cursor: pointer;
    }
  }
  .content {
    flex: 1;
    background: #fff;
    .item {
      height: 100%;
      flex: 1;
      border-right: 1px dotted#0db168;
      img {
        width: 68px;
        height: 68px;
        margin: 0 5px;
        margin-left: 10px;
      }
      &.empty {
        align-items: flex-start;
        padding: 20px 0;
        box-sizing: border-box;
      }
      .number {
        padding: 0 10px;
        box-sizing: border-box;
        text-align: center;
        margin-right: 10px;
        background-color: #f6f6f6;
        color: #999999;
        font-size: 36px;
        + span {
          font-size: 14px;
          color: #999999;
        }
      }
      .flex-y {
        flex: 1;
        justify-content: space-evenly;
        &:hover {
          span:nth-of-type(3) {
            font-size: 12px;
            color: #0db168;
            cursor: pointer;
          }
        }
        span {
          &:nth-of-type(1) {
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
            font-size: 15px;
            text-align: start;
          }
          &:nth-of-type(2) {
            color: #ff0036;
            font-size: 17px;
          }
          &:nth-of-type(3) {
            font-size: 12px;
            color: #ffffff;
            cursor: pointer;
          }
        }
      }
    }
    .add {
      width: 160px;
      button {
        // background: #0db168;
        border: none;
      }
      span {
        margin-top: 10px;
        color: #0db168;
        cursor: pointer;
      }
    }
  }
}
</style>
